<template>
  <div class="bg">
    <section class="banner">
      <img class="bannerImg" src="../../assets/img/join/join-banner.png" alt="" width="100%">
      <div class="banner-block">
        <div class="title">
          <h1 class="banner-title hidden-xs-only">加入我们</h1>
          <svg-icon class="banner-line-img hidden-xs-only" svg-name="title-white"></svg-icon>
          <h2 class="banner-sutitle hidden-xs-only">移动智能终端生态联盟是国内领先的行业非盈利性组织，由领先的智能终端厂商联合发起和建立，旨在推动全行业应用生态标准化、规范化、合规化、高效化，提升全行业应用服务水平和能力，促进行业共赢、共发展。</h2>
        </div>
        <svg-button
          class="banner-button"
          @click.native="openModal()"
          svg-name="banner-button-text"
          button-class="banner-button-text"
          svg-hover-name="banner-hover-button"
          text="现在加入"
          text-color="#D8CFA9"
          text-hover-color="#202020"
        ></svg-button>
      </div>
    </section>

    <el-row>
      <el-col :xs="{span:24,offset:0}" :span="18" :offset="3">
        <section class="text-center">
          <h3 class="section-title">我们在寻找这样的伙伴</h3>
          <svg-icon class="section-img hidden-xs-only" svg-name="title-white"></svg-icon>
        </section>
      </el-col>
    </el-row>

    <section class="content-block">
      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="11" :offset="1">
          <section class="section-block">
            <svg-icon class="icon-img" svg-name="战略合作hover"></svg-icon>
            <div>
              <p class="title">成为金标联盟成员</p>
              <p class="sub-title">参与技术标准制定，共享联盟服务资源，推动行业应用生态建设。</p>
              <svg-button
                class="button"
                @click.native="openModal()"
                svg-name="成为战略生态合作伙伴hover"
                svg-hover-name="成为战略生态合作伙伴a"
                text="成为金标联盟成员"
                text-color="#202020"
                text-hover-color="#D8CFA9"
              ></svg-button>
            </div>
          </section>
        </el-col>
        <el-col :xs="24" :span="11" :offset="0">
          <section class="section-block">
            <svg-icon class="icon-img" svg-name="联盟注册籍会员hover"></svg-icon>
            <div>
              <p class="title">联盟注册籍会员</p>
              <p class="sub-title">获得联盟权益，参与联盟活动，共享联盟服务资源与技术标准。</p>
              <router-link to="/account/register">
                <svg-button
                  class="button"
                  svg-name="成为联盟注册籍会员hover"
                  svg-hover-name="成为联盟注册籍会员a"
                  text="成为联盟注册籍会员"
                  text-color="#202020"
                  text-hover-color="#D8CFA9"
                ></svg-button>
              </router-link>
            </div>
          </section>
        </el-col>
      </el-row>
    </section>

    <section class="row-block3">
      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="18" :offset="3">
          <section class="text-center">
            <h3 class="section-title">我们的能力，让合作共赢真实可靠</h3>
            <svg-icon class="section-img hidden-xs-only" svg-name="title-black"></svg-icon>
          </section>
        </el-col>
      </el-row>

      <section class="content-block">
        <el-row>
          <el-col :xs="{span:24,offset:0}" :span="8">
            <section class="section-block3">
              <svg-icon class="section-icon" svg-name="领先的厂商联合建立"></svg-icon>
              <svg-icon class="section-line" svg-name="small-line"></svg-icon>
              <div class="block3-title">
                <svg-icon class="icon-img-star" svg-name="星形"></svg-icon>
                <span>领先的厂商联合建立</span>
              </div>
              <p class="block3-subtitle">由中国领先的智能终端厂商发起，拥有广泛的影响力。多方面的应用认证权威、可靠，可信。</p>
            </section>
          </el-col>
          <el-col :xs="{span:24,offset:0}" :span="8">
            <section class="section-block3">
              <svg-icon class="section-icon" svg-name="庞大的应用生态"></svg-icon>
              <svg-icon class="section-line" svg-name="small-line"></svg-icon>
              <div class="block3-title">
                <svg-icon class="icon-img-star" svg-name="星形"></svg-icon>
                <span>庞大的应用生态</span>
              </div>
              <p class="block3-subtitle">覆盖广阔市场的应用生态，囊括市场上大部分的应用和开发者，服务类型多样，服务效果显著。</p>
            </section>
          </el-col>
          <el-col :xs="{span:24,offset:0}" :span="8">
            <section class="section-block3">
              <svg-icon class="section-icon" svg-name="覆盖面极广的用户"></svg-icon>
              <svg-icon class="section-line" svg-name="small-line"></svg-icon>
              <div class="block3-title">
                <svg-icon class="icon-img-star" svg-name="星形"></svg-icon>
                <span>覆盖面极广的用户</span>
              </div>
              <p class="block3-subtitle">深受用户喜爱的移动智能终端品牌，能够面向数亿用户提供统一、便捷、高效的服务和产品。</p>
            </section>
          </el-col>
        </el-row>
      </section>
    </section>

    <el-row>
      <el-col :xs="{span:24,offset:0}" :span="18" :offset="3">
        <section class="text-center">
          <h3 class="section-title">您将获得这些</h3>
          <svg-icon class="section-img hidden-xs-only" svg-name="title-white"></svg-icon>
        </section>
      </el-col>
    </el-row>

    <section class="content-block">
      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="11" :offset="1">
          <section class="section-block">
            <svg-icon svg-name="01金标认证资格" class="icon-img-n"></svg-icon>
            <div>
              <div class="title">
                <svg-icon class="icon-img-star2" svg-name="星形2"></svg-icon>
                <span>金标认证资格</span>
              </div>
              <p class="sub-title">我们的伙伴所生产的应用将有资格在各大应用市场获得标示应用安全、可靠、可信的「金标」，提升用户信任感，提高应用价值。</p>
            </div>
          </section>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :span="11">
          <section class="section-block">
            <svg-icon svg-name="02建立行业标准参与资格" class="icon-img-n"></svg-icon>
            <div>
              <div class="title">
                <svg-icon class="icon-img-star2" svg-name="星形2"></svg-icon>
                <span>建立行业标准参与资格</span>
              </div>
              <p
                class="sub-title"
              >我们的伙伴有资格参与到包括兼容性、稳定性、安全与隐私等多种类似的行业统一标准的建立与制定的工作中，推动全行业质量提升，服务水准提高。</p>
            </div>
          </section>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="11" :offset="1">
          <section class="section-block">
            <svg-icon svg-name="03联盟活动参与资格" class="icon-img-n"></svg-icon>
            <div>
              <div class="title">
                <svg-icon class="icon-img-star2" svg-name="星形2"></svg-icon>
                <span>联盟活动参与资格</span>
              </div>
              <p class="sub-title">在联盟面向开发者、行业、用户等举办的活动中，我们的伙伴均有资格和机会参与其中，获得与行业、用户、开发者交流和讨论的机会。</p>
            </div>
          </section>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :span="11">
          <section class="section-block">
            <svg-icon svg-name="04应用生态优先服务资格" class="icon-img-n"></svg-icon>
            <div>
              <div class="title">
                <svg-icon class="icon-img-star2" svg-name="星形2"></svg-icon>
                <span>应用生态优先服务资格</span>
              </div>
              <p class="sub-title">我们的应用生态为我们的伙伴提供优先服务资格，包括优先审核、优先认证、优先咨询等服务，满足应用开发方方面面的需求。</p>
            </div>
          </section>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="11" :offset="1">
          <section class="section-block">
            <svg-icon svg-name="05领先的厂商通用服务" class="icon-img-n"></svg-icon>
            <div>
              <div class="title">
                <svg-icon class="icon-img-star2" svg-name="星形2"></svg-icon>
                <span>领先的厂商通用服务</span>
              </div>
              <p class="sub-title">我们领先的测试工具、生产力工具、合规工具等均面向我们的伙伴开放。我们的伙伴能够利用厂商领先的工具和平台，提高生产效率。</p>
            </div>
          </section>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :span="11">
          <section class="section-block">
            <svg-icon svg-name="06生态成员价值" class="icon-img-n"></svg-icon>
            <div>
              <div class="title">
                <svg-icon class="icon-img-star2" svg-name="星形2"></svg-icon>
                <span>生态成员价值</span>
              </div>
              <p class="sub-title">成为我们的伙伴，将享受到联盟中的各项权利和资格，并与全行业领先的伙伴们共同交流、协作。</p>
            </div>
          </section>
        </el-col>
      </el-row>
    </section>

    <el-row>
      <el-col :xs="{span:24,offset:0}" :span="18" :offset="3">
        <a href="/home/constitution" target="_blank">
          <svg-button
            class="click-button"
            svg-name="更多权益请参阅我们的《章程》a"
            svg-hover-name="更多权益请参阅我们的《章程》hover"
            text="更多权益请参阅我们的《章程》"
            button-class="click-button-text"
            text-color="#202020"
            text-hover-color="#D8CFA9"
          ></svg-button>
        </a>
      </el-col>
    </el-row>

    <section class="footContentBox">
      <p class="footcontentTitle">加入我们，与全行业共享发展机会</p>
      <svg-button
        class="footButtonBox"
        @click.native="openModal()"
        svg-name="foot-button"
        svg-hover-name="foot-hover-button"
        text="立即加入"
        text-color="#202020"
        text-hover-color="#D8CFA9"
      ></svg-button>
    </section>
  </div>
</template>

<script>
import SvgButton from "@/components/SvgButton";
export default {
  metaInfo: {
    title:"ITGSA | 移动智能终端生态联盟 | 加入我们",
    meta: [
      {
        name: 'keywords',
        content: '金标认证资格，建立行业标准，应用生态优先服务资格，庞大的应用生态，覆盖面极广的用户'
      },
      {
        name: 'description',
        content: '移动智能终端生态联盟是国内领先的行业非盈利性组织，由领先的智能终端厂商联合发起和建立，旨在推动全行业应用生态标准化、规范化、合规化、高效化，提升全行业应用服务水平和能力，促进行业共赢、共发展。'
      },
    ]
  },
  components: {
    SvgButton,
  },
  methods: {
    openModal() {
      this.$msgBox
        .accountWhiteMessageBox({
          title: "",
          content: "",
        })
        .then(async (data) => {})
        .catch((val) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.bg {
  .banner {
    position: relative;
    width: 100%;
    min-height: 300px;
    .bannerImg{
      width: 100%;
      min-height: 300px;
    }
  }
}


@media screen and (max-width: 767px) {
  .banner {
    background: url(../../assets/img/join/banner-m.jpg) bottom no-repeat;
    background-size: cover;
      .bannerImg{
        display: none;
      }
  }
}

.banner-block {
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
  width: 946px;

  .title {
    height: 88%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .banner-block {
    width: 100%;
  }
}

.banner-line-img {
  width: 382px;
  height: 27px;
}

.banner-title {
  font-size: 48px;
  line-height: 100px;
  text-align: center;
  font-weight: 900;
  color: #f2e9c1;
}

.banner-sutitle {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  color: rgba(242, 233, 193, 1);
  line-height: 20px;
}

.banner-button {
  position: absolute;
  font-size: 18px;
  transform: translateX(-50%);
  bottom: 10%;
  left: 50%;
  width: 203px;
  height: 60px;
  cursor: pointer;
}


.section-title {
  margin-top: 80px;
  font-size: 36px;
  font-weight: bold;
  color: #c3ba93;
}

.section-img {
  width: 882px;
  height: 37px;
  margin-top: 20px;
}

.section-block {
  width: 500px;
  height: 250px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 5px;
  margin: 30px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .icon-img {
    width: 120px;
    height: 120px;
    // background: url(../../assets/img/join/hezuo.png) top no-repeat;
    // background-size: cover;
  }

  // .icon-img1 {
  //   width: 169px;
  //   height: 169px;
  //   background: url(../../assets/img/join/huiyuan.png) top no-repeat;
  //   background-size: cover;
  // }
  // .line-img {
  //   width: 1px;
  //   height: 140px;
  //   background: url(../../assets/img/join/line.png) top no-repeat;
  //   background-size: cover;
  //   visibility: hidden;
  // }

  .title {
    width: 230px;
    font-size: 20px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 30px;
    height: 30px;
    display: flex;
    align-items: baseline;

    .icon-img-star2 {
      width: 16px;
      height: 16px;
    }

    span {
      margin-left: 8px;
    }
  }

  .sub-title {
    margin: 15px 0;
    width: 230px;
    font-size: 14px;
    font-weight: 300;
    color: rgba(153, 153, 153, 1);

    line-height: 24px;
  }

  .button {
    width: 190px;
    height: 38px;
    // text-align: center;
    font-size: 14px;
    // font-weight: 300;
    // line-height: 38px;
    // // color: rgba(255, 255, 255, 1);
    // // background: rgba(40, 38, 44, 1);
    // color: rgba(40, 38, 44, 1);
    // background: rgba(255, 255, 255, 1);
    // border: 1px solid rgba(40, 38, 44, 1);
    // box-shadow: 0px 2px 3px 0px rgba(151, 156, 165, 0.61);
    // border-radius: 19px;
    cursor: pointer;

    // &:hover {
    //   color: rgba(242, 233, 193, 1);
    //   border: 1px solid rgba(201, 167, 119, 1);
    //   box-shadow: none;
    //   color: rgba(255, 255, 255, 1);
    //   background: rgba(40, 38, 44, 1);

    //   .button-next {
    //     background: rgba(242, 233, 193, 1);
    //     color: rgba(32, 32, 32, 1);
    //   }
    // }
  }

  // .button-next {
  //   display: inline-block;
  //   width: 18px;
  //   height: 18px;
  //   // background: rgba(242, 233, 193, 1);
  //   border-radius: 50%;
  //   font-size: 12px;
  //   font-weight: bold;
  //   // color: rgba(32, 32, 32, 1);
  //   color: rgba(255, 255, 255, 1);
  //   background: rgba(32, 32, 32, 1);
  //   line-height: 18px;
  // }

  .icon-img-n {
    width: 110px;
    height: 110px;
  }
  // .icon-img-n1 {
  //   width: 110px;
  //   height: 110px;
  //   background: url(../../assets/img/join/1.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n2 {
  //   width: 110px;
  //   height: 110px;
  //   background: url(../../assets/img/join/2.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n3 {
  //   width: 110px;
  //   height: 110px;
  //   background: url(../../assets/img/join/3.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n4 {
  //   width: 110px;
  //   height: 110px;
  //   background: url(../../assets/img/join/4.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n5 {
  //   width: 110px;
  //   height: 110px;
  //   background: url(../../assets/img/join/5.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n6 {
  //   width: 110px;
  //   height: 110px;
  //   background: url(../../assets/img/join/6.png) top no-repeat;
  //   background-size: cover;
  // }
  // &:hover {
  // background: rgba(40, 38, 44, 1);

  // .title {
  //   color: rgba(254, 254, 254, 1);
  // }

  // .button {
  //   color: rgba(242, 233, 193, 1);
  //   border: 1px solid rgba(201, 167, 119, 1);
  //   box-shadow: none;
  //   color: rgba(255, 255, 255, 1);
  //   background: rgba(40, 38, 44, 1);
  // }

  // .button-next {
  //   background: rgba(242, 233, 193, 1);
  //   color: rgba(32, 32, 32, 1);
  // }

  // .line-img {
  //   visibility: visible;
  // }

  // .icon-img {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/hezuo_s.png) top no-repeat;
  //   background-size: cover;
  // }

  // .icon-img1 {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/huiyuan_s.png) top no-repeat;
  //   background-size: cover;
  // }

  // .icon-img-n1 {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/1s.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n2 {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/2s.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n3 {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/3s.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n4 {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/4s.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n5 {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/5s.png) top no-repeat;
  //   background-size: cover;
  // }
  // .icon-img-n6 {
  //   width: 85px;
  //   height: 85px;
  //   background: url(../../assets/img/join/6s.png) top no-repeat;
  //   background-size: cover;
  // }
  // }
}

@media screen and (max-width: 767px) {
  .section-block {
    width: 90%;
    max-width: 426px;
  }
}

.row-block3 {
  margin-top: 40px;
  width: 100%;
  height: 507px;
  background: rgba(40, 38, 44, 1);
}

@media screen and (max-width: 767px) {
  .row-block3 {
    height: 1100px;
  }
}

.section-block3 {
  margin: 30px auto;
  width: 200px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .section-icon {
    width: 85px;
    height: 85px;
  }
  .section-line {
    margin-top: 5px;
    width: 118px;
    height: 13px;
  }

  .block3-title {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(254, 254, 254, 1);
    line-height: 30px;
    display: flex;
    align-items: baseline;

    .icon-img-star {
      width: 16px;
      height: 16px;
    }
    span {
      margin-left: 8px;
    }
  }

  .block3-subtitle {
    margin-top: 13px;
    font-size: 14px;
    font-weight: 300;
    color: rgba(153, 153, 153, 1);
    line-height: 24px;
  }
}

.click-button {
  margin: 20px auto 70px;
  position: relative;
  width: 286px;
  height: 44px;
  font-size: 15px;
  cursor: pointer;
}

/*banner按钮字体样式*/
/deep/ .click-button-text {
  left: 8px;
}
  @media screen and (max-width: 768px) {
    .bg{
      /*处理 banner-button 遮住文字*/
      .banner-button{
        bottom:0 !important;
        width:130px !important;
      }
    }
  }
</style>
